<template>
  <div class="c-container__body">
    <div class="o-row o-row-tiny">
      <div class="o-layout">
        <h3 class="c-title c-title--event u-1-of-1 u-push-1-of-5-at-small u-3-of-5-at-small
         u-padding-left-medium u-margin-bottom-none">
          {{panel.name}} - {{blockTitle}}
        </h3>
      </div>
    </div>

    <div class="o-row o-row-tiny u-padding-bottom-huge">
      <template v-for="pass in passes">
        <template v-for="blockPart in blockParticipationsSorted" >
          <div v-if="blockPart.filler" :key="pass.exerciseTypeId + '|' + pass.pass + '|' + blockPart.id"
               class="o-layout u-margin-bottom-medium c-exercise" >
            <div class="o-layout__item u-1-of-1 u-1-of-6-at-extralarge c-exercise__status">
              <span class="c-exercise__status-label">{{$t('session.order.blockFiller')}}</span>
            </div>
          </div>
          <exercise v-else
            :key="pass.exerciseTypeId + '|' + pass.pass + '|' + blockPart.id"
            :blockParticipation="blockPart"
            :exerciseTypeId="pass.exerciseTypeId"
            :pass="pass.pass"
            :panelId="panel.id"
            @open="openExercise($event)"></exercise>
        </template>
      </template>
    </div>
    <div class="c-actions">
      <div class="c-actions__buttons">
      </div>

      <div class="c-actions__buttons">
        <button v-on:click="closeBlock()" class="c-button c-button--small c-button--secondary">
          {{$t('close')}} ×
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  const VueScrollTo = require('vue-scrollto');

  import filter from 'lodash/filter';
  import find from 'lodash/find';

  import blockLib from 'client/lib/block.js'
  import panelLib from 'client/lib/panelHandler'
  import sessionLib from 'client/lib/session'

  export default {
    name: 'block',
    data: function() {
      return {
        blockParticipationsSorted: [],
        passes: [],
        colors: {
          absent: {
            color: '#999',
            label: 'absent/reserve'
          },
          dns: {
            color: '#666',
            label: 'dns'
          },
          finished: {
            color: 'blue',
            label: 'finished'
          },
          open: {
            color: 'orange',
            label: 'open'
          },
          scheduled: {
            color: 'red',
            label: 'scheduled'
          },
          missing: {
            color: 'white',
            label: 'missing'
          }
        }
      }
    },
    components: {
      "exercise": require('client/components/models/blockExercise.vue').default,
    },
    computed: {
      discipline: function() {
        return this.$store.state.eventDiscipline.discipline;
      },
      panel: function() {
        return find(this.$store.state.panels.items, item => {
          return item.id === this.$route.params.panelId;
        });
      },
      settings: function() {
        return find(this.$store.state.panels.configurations, i => i.panelId === this.$route.params.panelId)
      },
      block: function() {
        return this.$store.state.block.block;
      },
      blockTitle: function() {
        return blockLib.getBlockTitle(this.block, {full: false})
      },
      blockParticipations: function() {
        return this.$store.state.blockParticipations.items;
      },
      participations: function() {
        return this.$store.state.participations.items;
      },
      exercise: function() {
        return this.$store.state.exercise.exercise;
      }
    },
    created: function() {
      this.initBlock();
    },
    mounted: function() {
      if (this.exercise && this.exercise.id) {
        this.$nextTick(() => {
           document.querySelector('.js-header') ? VueScrollTo.scrollTo('#ex-' + this.exercise.id, 300, {offset: -170}) : VueScrollTo.scrollTo('#ex-' + this.exercise.id, 300);
        });
      }
    },
    watch: {
      block: function() {
        this.initBlock();
      },
      blockParticipations: function() {
        this.initBlock();
      },
      panel: function() {
        this.initBlock();
      },
    },
    methods: {
      initBlock: function() {
        if (this.block) {
          const session = find(this.$store.state.sessions.items, item => {
            return item.id === this.panel.sessionId;
          });
          const rotationType = sessionLib.getSessionRotationType(session)

          this.passes = panelLib.calculatePasses(this.panel, this.settings);

          const blockParticipations = filter(this.$store.state.blockParticipations.items, item => {
            if (this.block.id === item.blockId) {
              if (rotationType === 'mixed') {
                return blockLib.checkMixed(item, this.getParticipation(item.participationId), session, this.panel);
              }

              return true;
            }

            return false;
          });

          this.blockParticipationsSorted = blockLib.orderBlockParticipations(blockParticipations,
            this.block, session, this.panel.activeRotation);
        }
        else {
          this.blockParticipationsSorted = [];
        }
      },
      getParticipation: function(participationId) {
        return filter(this.participations, item => { return item.id === participationId; })[0];
      },
      openExercise: function(event) {
        this.$store.dispatch("panel.setStatus",
          { panelId: this.panel.id, newStatus: 'exercise', exerciseId: event.exerciseId, pass: event.pass});
      },
      closeBlock: function() {
        this.$store.dispatch("panel.unsetActiveRotation", { panelId: this.panel.id });
      }
    }
  };
</script>

<style scoped>
</style>
